<template>
  <Layout>
    <div class="my-4">
      <div class="card">
        <div class="card-body">
          <h2 class="mb-4">Lista de interesses</h2>

          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Exibir
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >entradas
                </label>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <form @submit.prevent="createInterest" style="display: inline;">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="newInterestName"
                      type="text"
                      placeholder="Nome"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>

                  &nbsp; &nbsp;

                  <b-button size="sm" variant="primary" @click="createInterest">
                    Criar
                  </b-button>
                </form>

                &nbsp;

                <label class="d-inline-flex align-items-center">
                  Pesquisar:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Pesquisar interesse..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="interestData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(avatar)="data">
                <img
                  :src="
                    data.item.avatar
                      ? $getImageUrl(data.item.avatar.originalName)
                      : $defaultInterestImage
                  "
                  style="max-width: 200px; height: auto;"
                />
              </template>

              <template v-slot:cell(upload)="data">
                <vue-dropzone
                  id="dropzone"
                  :ref="`dropzones-${data.item.id}`"
                  :options="uploadOptions"
                  :useCustomSlot="true"
                  @vdropzone-success="
                    (_, response) => avatarUploaded(data.item, response)
                  "
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Upload
                    </h3>
                  </div>
                </vue-dropzone>
              </template>

              <template v-slot:cell(actions)="data">
                <button
                  @click="editInterest(data.item)"
                  class="btn btn-warning mr-2"
                >
                  Editar
                </button>
                <button
                  class="btn btn-danger"
                  @click="deleteInterest(data.item)"
                >
                  Excluir
                </button>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import * as api from "@/api";
import Layout from "../layouts/main";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "ListInterests",

  components: {
    Layout,
    vueDropzone: vue2Dropzone,
  },

  data() {
    return {
      newInterestName: "",
      interestData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Nome", sortable: true },
        { key: "avatar", label: "Preview", sortable: false },
        { key: "upload", label: "Upload", sortable: false },
        { key: "actions", label: "Opções", sortable: false },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.interestData.length;
    },

    uploadOptions() {
      return {
        url: `${api.getBaseUrl()}/upload?width=128&height=128`,
        maxFilesize: 5,
        paramName: "image",
        maxFiles: 1,
        acceptedFiles: "image/*",
        init() {
          this.on("addedfile", function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0]);
            }
          });
        },
      };
    },
  },

  created() {
    this.reloadInterests();
  },

  methods: {
    reloadInterests() {
      api
        .getAllInterests()
        .then((interests) => {
          this.interestData = interests;
          this.totalRows = this.interestData.length;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar interesses!", "error");
        });
    },

    createInterest() {
      if (!this.newInterestName) {
        return this.$swal(
          "Opa",
          "Não posso criar um interesse sem nome!",
          "warning"
        );
      }

      api
        .createInterest(this.newInterestName)
        .then(() => {
          return this.reloadInterests();
        })
        .then(() => {
          this.$swal("Sucesso", "Interesse salvo", "success");
          this.newInterestName = "";
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao criar interesse", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },

    editInterest(interest) {
      Swal.fire({
        title: "Novo nome",
        input: "text",
        showCloseButton: true,
        confirmButtonText: "Confirmar",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#02a499",
        allowOutsideClick: false,
      }).then(({ isDismissed, value: newName }) => {
        if (isDismissed) {
          return;
        }
        this.doEditInterest(interest, newName);
      });
    },

    doEditInterest(interest, newName) {
      api
        .updateInterest(interest.id, { name: newName })
        .then(() => this.reloadInterests())
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Nome atualizado!",
          });
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao atualizar interesse", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleteInterest(interest) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.value) {
          return this.doDeleteInterest(interest);
        }
      });
    },

    doDeleteInterest(interest) {
      api
        .deleteInterest(interest.id)
        .then(() => this.reloadInterests())
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Interesse removido!",
          });
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao remover interesse", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },

    avatarUploaded(interest, response) {
      return api
        .updateInterest(interest.id, { avatarId: response.id })
        .then(() => this.reloadInterests())
        .then(() => {
          this.$refs[`dropzones-${interest.id}`].removeAllFiles();
          Swal.fire({
            icon: "success",
            title: "Imagem atualizada!",
          });
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao atualizar interesse", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },
  },
};
</script>

<style lang="scss">
.vue-dropzone > .dz-preview .dz-image {
  height: 200px !important;
  width: 100% !important;
  border-radius: 8px;
}
</style>
